<template>
  <b-sidebar
    id="opened-task-form"
    :visible="openedTaskForm"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-task-form', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('project.task.label.tz') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-card class="card-transaction">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="mb-2"
          >
            <b-button
              v-if="userData.telegram && userData.telegram !== ''"
              variant="warning"
              block
              class="mb-2"
              @click="sendToTG()"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
              />
              {{ $t('project.task.btn.sendtg') }}
            </b-button>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="SlidersIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="task-title">
                    {{ $t('project.task.label.format') }}
                  </h6>
                  <div class="font-weight-bolder">
                    {{ $t(`catalog.price.types.${task.type}`) }}
                  </div>
                </b-media-body>
              </b-media>
            </div>

            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="CalendarIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="task-title">
                    {{ $t('project.task.label.date') }}
                  </h6>
                  <div class="font-weight-bolder">
                    {{ task.started_at }} - {{ task.ended_at }}
                  </div>
                </b-media-body>
              </b-media>
            </div>

            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="ClockIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="task-title">
                    {{ $t('project.task.label.time') }}
                  </h6>
                  <div class="font-weight-bolder">
                    {{ task.hours_started_at }} - {{ task.hours_ended_at }}
                  </div>
                </b-media-body>
              </b-media>
            </div>

            <div
              v-if="task.comment"
              class="transaction-item"
            >
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="AlertTriangleIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="task-title">
                    {{ $t('project.start.label.comment') }}
                  </h6>
                  <div class="font-weight-bolder">
                    {{ task.comment }}
                  </div>
                </b-media-body>
              </b-media>
            </div>

            <div
              v-if="task.link"
              class="transaction-item"
            >
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="LinkIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="task-title">
                    {{ $t('project.task.label.approve') }}
                  </h6>
                  <div class="font-weight-bolder">
                    <a
                      :href="task.link"
                      target="_blank"
                    >
                      {{ task.link }}
                    </a>
                  </div>
                </b-media-body>
              </b-media>
            </div>

            <div v-if="extractLinks" class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="AlignJustifyIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="task-title">
                    Посилання в пості
                  </h6>

                  <b-alert
                    variant="danger"
                    class="p-1"
                    show
                  >
                    Перевірте коректність посиланнь
                  </b-alert>

                  <b-form-group
                    v-for="(btn, index) in extractLinks"
                    :key="`btn_${index}`"
                    :label="btn.anchor"
                    :label-for="`btn_${index}`"
                  >
                    <b-input-group>
                      <b-form-input
                        :id="`btn_${index}`"
                        :value="btn.url"
                        readonly
                        disabled
                      />
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          block
                          @click="doCopy(btn.url)"
                        >
                          <feather-icon
                            icon="ClipboardIcon"
                            size="14"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-media-body>
              </b-media>
            </div>

            <div v-if="task.buttons && task.buttons.length" class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="18"
                      icon="AlignJustifyIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="task-title">
                    Кнопки в пості
                  </h6>

                  <b-alert
                    variant="danger"
                    class="p-1"
                    show
                  >
                    Перевірте посилання в кнопках
                  </b-alert>
                  <b-form-group
                    v-for="(btn, index) in task.buttons"
                    :key="`btn_${index}`"
                    :label="btn.title"
                    :label-for="`btn_${index}`"
                  >
                    <b-input-group>
                      <b-form-input
                        :id="`btn_${index}`"
                        :value="btn.href"
                        readonly
                        disabled
                      />
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          block
                          @click="doCopy(btn.href)"
                        >
                          <feather-icon
                            icon="ClipboardIcon"
                            size="14"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-card
              v-if="task.type === 'repost'"
              :header="$t('project.task.label.repost')"
              header-tag="h6"
              class="pre_col"
            >
              <b-card-text
                class="mt-1"
                v-html="task.description"
              />
            </b-card>

            <b-card
              v-else
              :header="$t('project.task.label.post')"
              header-tag="h6"
              class="pre_col"
            >
              <template
                v-if="task.gallery && task.gallery.length"
              >
                <div class="pre_images big">
                  <a
                    :href="task.gallery[0].media.url"
                    @click.prevent="downloadFile(task.gallery[0].media.url)"
                  >
                    <template v-if="task.gallery[0].media.url.split('.').pop().trim().toLowerCase() !== 'mp4'">
                      <b-img
                        :src="$options.filters.mediaUrl(task.gallery[0], null, 'base')"
                      />
                    </template>
                    <template v-else>
                      <video
                        autoplay="autoplay"
                        height="100%"
                        loop="loop"
                        muted="muted"
                        preload="auto"
                        width="100%"
                      >
                        <source
                          :src="task.gallery[0].media.url"
                          type="video/mp4"
                        >
                        Your browser does not support the video tag.
                      </video>
                    </template>
                    <div class="download">
                      <b-avatar
                        rounded
                        size="42"
                        variant="light-primary"
                      >
                        <feather-icon
                          size="18"
                          icon="DownloadIcon"
                        />
                      </b-avatar>
                    </div>
                  </a>
                </div>
                <div class="pre_images">
                  <a
                    v-for="(file, index) in task.gallery"
                    :key="index"
                    :href="file.media.url"
                    :hidden="!index"
                    @click.prevent="downloadFile(file.media.url)"
                  >
                    <template v-if="file.media.url.split('.').pop().trim().toLowerCase() !== 'mp4'">
                      <b-img
                        :key="index"
                        :hidden="!index"
                        :src="$options.filters.mediaUrl(file, null, 'base')"
                      />
                    </template>
                    <template v-else>
                      <video
                        :key="index"
                        :hidden="!index"
                        autoplay="autoplay"
                        height="100%"
                        loop="loop"
                        muted="muted"
                        preload="auto"
                        width="100%"
                      >
                        <source
                          :src="file.media.url"
                          type="video/mp4"
                        >
                        Your browser does not support the video tag.
                      </video>
                    </template>
                    <div class="download">
                      <b-avatar
                        rounded
                        size="42"
                        variant="light-primary"
                      >
                        <feather-icon
                          size="18"
                          icon="DownloadIcon"
                        />
                      </b-avatar>
                    </div>
                  </a>
                </div>
              </template>
              <b-card-text
                class="mt-1"
                v-html="task.description"
              />
              <template v-if="task.buttons">
                <b-button
                  v-for="(btn, index) in task.buttons"
                  :key="`btn_${index}`"
                  variant="outline-primary"
                  class="mt-1"
                  block
                  v-text="btn.title"
                />
              </template>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-table
              responsive
              :items="task.history"
              :fields="historyTableColumns"
            >
              <template #cell(status)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ $t(`general.taskStatus.${data.item.status}`) }}
                </b-card-text>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>

    </template>
  </b-sidebar>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import download from 'downloadjs'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  mixins: [GlobalMixin],
  model: {
    prop: 'openedTaskForm',
    event: 'update:opened-task-form',
  },
  props: {
    openedTaskForm: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    setStatus: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      historyTableColumns: [
        { key: 'created_at', label: this.$t('market.table.fields.date'), sortable: false },
        { key: 'status', label: this.$t('market.table.fields.status'), sortable: false },
        { key: 'comment', label: this.$t('market.table.fields.comment'), sortable: false },
      ],
    }
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('userData'))
    },
    extractLinks() {
      const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*?>(.*?)<\/a>/g
      const links = []

      let match
      // eslint-disable-next-line no-cond-assign
      while ((match = linkRegex.exec(this.task.description)) !== null) {
        const [, url, anchor] = match
        const cleanedAnchor = anchor.replace(/<[^>]*>/g, '')
        const cleanedAnchor2 = cleanedAnchor.replace(/&nbsp;/g, '')
        if (cleanedAnchor2 && url) {
          links.push({ anchor: cleanedAnchor, url })
        }
      }

      return links.length ? links : null
    },
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-task-form', false)
    },
    downloadFile(url) {
      download(url, url.split('/').pop().trim().toLowerCase())
    },
    sendToTG() {
      this.$http.post(`/api/user/tasks/${this.task.id}/tg`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$i18n.t('notifications.successfully'),
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    doCopy(text) {
      this.$copyText(text).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Text copied',
            icon: 'BellIcon',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-task-form {
  min-width: 50%;

  .vs__dropdown-menu {
    max-height: 200px !important;
  }

  .pre_col {
    background-color: rgba(34, 41, 47, 0.05) !important;

    .form-text.text-muted {
      color: #39414e;
    }

    .pre_images {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap:2px;

      &.big {
        margin-bottom: 2px;
        grid-template-columns: 1fr;
      }

      a {
        display: block;
        position: relative;

        img {
          display: block;
          position: relative;
          z-index: 1;
          max-width: 100%;
          width: 100%;
          height: auto;
        }

        .download {
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
          position: absolute;
          left:0;
          top:0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 2;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .table-responsive,
    table,
    table * {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: 100%;
      overflow: scroll;
    }

    table thead {
      display: none !important;
    }

    table tr {
      border:1px solid #eee;
      border-radius: 4px;
      margin: 0 0 1em;
    }

    table tr td {
      padding: 10px;
    }
  }
}
</style>
