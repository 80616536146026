<template>
  <b-sidebar
    id="opened-task-review"
    :visible="openedTaskReview"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-task-review', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('project.task.label.review') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-card class="card-transaction">
        <div class="rate_block">
          <b-form-radio
            v-for="r in [1,2,3,4,5]"
            :key="r"
            v-model="rating"
            name="rating"
            :value="r"
          >
            <feather-icon
              class="cursor-pointer"
              icon="StarIcon"
              size="26"
              :class="r <= rating ? 'text-warning' : 'text-secondary'"
            />
          </b-form-radio>
        </div>
        <b-form-group
          label-for="description"
        >
          <b-form-textarea
            id="description"
            v-model="description"
            :placeholder="$t('project.task.label.review')"
          />
        </b-form-group>
        <b-button
          variant="primary"
          block
          @click="writeReview(task.product.id, rating, description)"
        >
          {{ $t('project.task.btn.writecomment') }}
        </b-button>

      </b-card>

    </template>
  </b-sidebar>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
  model: {
    prop: 'openedTaskReview',
    event: 'update:opened-task-review',
  },
  props: {
    openedTaskReview: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    writeReview: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      rating: 4,
      description: null,
    }
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-task-review', false)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-task-review {

  .rate_block {
    margin:10px 0 30px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap:20px;

    .custom-control.custom-radio {
      padding: 0 0 !important;

      .custom-control-label::before,
      .custom-control-label::after {
        display: none !important;
      }
    }
  }

  .vs__dropdown-menu {
    max-height: 200px !important;
  }

}
</style>
